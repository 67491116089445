import './search.element.scss';

export class SearchElement extends HTMLElement {
  async connectedCallback() {
    this.innerHTML = `
      <button id="searchButton" class="btn" type="button">
        <svg style="margin-right: 5px" xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20">
          <path d="M765-144 526-383q-30 22-65.792 34.5T384.035-336Q284-336 214-406t-70-170q0-100 70-170t170-70q100 0 170 70t70 170.035q0 40.381-12.5 76.173T577-434l239 239-51 51ZM384-408q70 0 119-49t49-119q0-70-49-119t-119-49q-70 0-119 49t-49 119q0 70 49 119t119 49Z"/>
        </svg>
        Search <span>⌘K</span>
      </button>
<div id="searchModal" style="display: none">
    <div class="modal-content">
      <input id="modalSearch" type="text" placeholder="Search" aria-label="Search" aria-describedby="button-addon2">
      <p id="noResults">No recent searches</p>
      <div id="searchHits">
        <p>Results</p>
        <ul>
          <li>
            <a href="/guide/cms#general-workflow">
              <div class="DocSearch-Hit-Container">
                <div class="DocSearch-Hit-icon">
                  <svg width="20" height="20" viewBox="0 0 20 20">
                    <path d="M17 5H3h14zm0 5H3h14zm0 5H3h14z" stroke="currentColor" fill="none" fill-rule="evenodd" stroke-linejoin="round"></path>
                  </svg>
                </div>
                <div class="DocSearch-Hit-content-wrapper"><span class="DocSearch-Hit-title">Connecting VitePress to a CMS will largely revolve around …</span><span class="DocSearch-Hit-path">Connecting to a CMS &ZeroWidthSpace;</span></div>
                <div class="DocSearch-Hit-action">
                  <svg class="DocSearch-Hit-Select-Icon" width="20" height="20" viewBox="0 0 20 20">
                    <g stroke="currentColor" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round">
                      <path d="M18 3v4c0 2-2 4-4 4H2"></path>
                      <path d="M8 17l-6-6 6-6"></path>
                    </g>
                  </svg>
                </div>
              </div>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
    `;

    // when pressing command + k, open the modal
    document.addEventListener('keydown', (e) => {
      if (e.key === 'k' && e.metaKey) {
        document.getElementById('searchModal').style.display = 'block';
        document.getElementById('modalSearch').focus();
      }
    })

    document.getElementById('searchButton').addEventListener('click', () => {
      console.log('clicked')
      document.getElementById('searchModal').style.display = 'block';
      document.getElementById('modalSearch').focus();
    })

    // when pressing escape key, close the modal
    document.addEventListener('keydown', (e) => {
      if (e.key === 'Escape') {
        document.getElementById('searchModal').style.display = 'none';
      }
    })

    // if the modal is open, and you click outside of it, close the modal, however not if the element clicked is #searchButton
    document.addEventListener('click', (e) => {
      const target = e.target as HTMLElement;
      if (document.getElementById('searchModal').style.display === 'block' && target.id !== 'searchButton') {
        document.getElementById('searchModal').style.display = 'none';
      }
    })

  }
}

customElements.define('ipa-search', SearchElement);
