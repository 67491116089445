import {PlatformHooks} from "./hooks/platform";
import {HookTarget, returnErrorResponse} from "./services/api.service";
import {DataHooks} from "./hooks/data";

const _platformHooks = new PlatformHooks();
const _data = new DataHooks();

function validatePermission(permission:string,appPermissions:any){
  // return appPermissions.accepted && appPermissions.scope.includes(permission)
  return true
}

export function ipaSdkHooks(e: HookTarget) {
  if (e && e.data && e.data.__seq) {
    if(validatePermission(e.data.__permission,[])){
      switch (e.data.__api) {
        case 'platform':
          return _platformHooks.hookSwitch(e);
        case 'data':
          return _data.hookSwitch(e);
      }
    } else {
      returnErrorResponse(e,'App does not have the permission to execute api request');
    }

  }
}
