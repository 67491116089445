import {getAppState, listenForFeatureContextChangesSubject, writeToStore,platformInteractions} from "./ipa-core";
export interface IMenuItem {
  id:string,
  label:string,
  src:string,
  icon:string
}
export interface FeatureCardInterface {
  id: string
  url: string
  meta: {
    routes: {
      route: string,
      environment: string
    }[],
    name: string
    description: string
    banner: string
    tags: string[]
    scopes: string[]
    keywords: string[]
    discoverable: boolean,
    urlPathName: string,
    aaid: string,
    redirectUrl: string,
  }
}

export interface IPlatformInteraction{
  header?:{
    src?:string,
    height?:string
    show?:boolean
  },
  leftNav?:{
    src?:string,
    width?:string
    show?:boolean
  },
  leftNavMenuItems?:{
    show?:boolean,
    isOpen?:boolean
  },
  rightNav?:{
    src?:string,
    width?:string
    show?:boolean
  },
  secondaryApp?:{
    src?:string,
    show?:boolean
  }
}
export const defaultPlatformInteraction = {
  header:{
    src: undefined,
    height:'0',
    show:false
  },
  leftNav:{
    src:undefined,
    width:'250',
    show:false
  },
  leftNavMenuItems:{
    show:false,
    isOpen:true
  },
  rightNav:{
    src:undefined,
    width:'250',
    show:false
  },
  secondaryApp:{
    src:undefined,
    show:false
  }
}

export interface IUserAppStateEntity{
  featureData?:object,
  platformInteraction?: IPlatformInteraction,
  apps?:FeatureCardInterface[]
}

export class IUserAppStateEntityClass {

  emptyState() : IUserAppStateEntity {
    return {
      featureData:{},
      platformInteraction:defaultPlatformInteraction,
      apps:[]
    }
  }
}

export class UpdateAppState {

  all(payload: IUserAppStateEntity){

    if(payload?.featureData !== undefined) {
      this.features(payload.featureData)
    }
  }

  features(payload: any){
    const appState = getAppState()
    appState.featureData = {...appState.featureData,...payload};
    writeToStore(appState)
  }

  singleFeature(data :{ keyName:string,payload: any}){
    const appState = getAppState()
    // @ts-ignore
    appState.featureData[data.keyName] = {...appState.featureData[data.keyName],...data.payload};
    writeToStore(appState)
    listenForFeatureContextChangesSubject.next(data)
  }

  apps(payload: FeatureCardInterface[]){
    const appState = getAppState()
    appState.apps = [...payload];
    writeToStore(appState)
  }

  platformInteractions(payload: IPlatformInteraction){
    const appState = getAppState()

    function updateFromPartial<T>(obj: T, updates: Partial<T>):T {
      return {...obj, ...updates};
    }

    if(appState.platformInteraction){
      appState.platformInteraction = updateFromPartial(appState.platformInteraction,payload)
    } else {
      appState.platformInteraction = defaultPlatformInteraction
      appState.platformInteraction = updateFromPartial(appState.platformInteraction,payload)
    }

    platformInteractions.next(appState.platformInteraction)
    writeToStore(appState)
  }
}
