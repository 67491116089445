import './icon.element.scss';

export class IconElement extends HTMLElement {
  connectedCallback() {
    this.innerHTML = `
        <img width="28" height="28" />
      `;

    this.querySelector('img').src = this.dataset.src

  }

}

customElements.define('ipa-icon', IconElement);
