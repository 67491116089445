import './app.element.scss';
import './side-load/side-load.element';
import './header/header.element';
import './left-nav/left-nav.element';
import './right-nav/right-nav.element';
import './_components/icon/icon.element';
import './_components/left-nav-menu-items/left-nav-menu-items.element';
import {setupIpa} from "@web-internal-portal-apps/ipa-core";
import {environment} from "../environments/environment";

export class AppElement extends HTMLElement {
  public static observedAttributes = [];

  connectedCallback() {
    let app = this.dataset.app
    const activeApp = window.localStorage.getItem('activeApp')
    if(!environment.production) app = 'https://ipa-home.pages.dev'
    this.innerHTML = `
      <ipa-header></ipa-header>
      <ipa-left-nav></ipa-left-nav>
      <ipa-left-nav-menu-items id="ipaLeftNavMenuItemsContainerElement"></ipa-left-nav-menu-items>
      <ipa-right-nav></ipa-right-nav>
      <ipa-side-load data-app="${app}"></ipa-side-load>
      `;

    // TODO: activeApp not working
    if (activeApp) app = activeApp;
    setupIpa(app);
  }
}
customElements.define('web-internal-portal-apps-root', AppElement);
