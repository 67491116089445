import {
  HookTarget, returnErrorResponse,
  returnSuccessResponse
} from "../services/api.service";
import {
  getAppList,
  getAppState,
  getSpecificAppState,
  listenForFeatureContextChangesSubject
} from "@web-internal-portal-apps/ipa-core";

export class DataHooks {
  hookSwitch(e: HookTarget) {
    switch (e.data.__id) {
      case 'getAllUserContext':
        this.getAppStateAndPostBack(e);
        break;
      case 'getFeatureContext':
        this.getFeatureContext(e);
        break;
      case 'getAllFeatureContext':
        this.getAllFeatureContext(e);
        break;
      case 'listenForFeatureContextChanges':
        this.listenForFeatureContextChanges(e);
        break;
      case 'getAppList':
        this.getAppList(e);
        break;
    }
  }

  getAppStateAndPostBack(target: HookTarget): void {
    const timeToExecute = window.performance.now();
    const state = getAppState();
    returnSuccessResponse(target, state,timeToExecute)
  }

  getFeatureContext(target: HookTarget): void {
    const timeToExecute = window.performance.now();
    const state = getSpecificAppState(target.data.__msg);
    returnSuccessResponse(target, state,timeToExecute)
  }

  getAllFeatureContext(target: HookTarget): void {
    const timeToExecute = window.performance.now();
    const state = getAppState().featureData;
    returnSuccessResponse(target, state,timeToExecute)
  }

  listenForFeatureContextChanges(target: HookTarget): void {
    listenForFeatureContextChangesSubject.subscribe(res => {
      target.source.postMessage(
        {
          data: res,
          __id: 'listenForFeatureContextChanges',
        },
        '*'
      );
    })
  }

  getAppList(target: HookTarget): void {
    const timeToExecute = window.performance.now();
    getAppList().then((res) => {
      returnSuccessResponse(target,res,timeToExecute)
    }).catch(error => {
      returnErrorResponse(target,error,timeToExecute)
    })
  }

}
