import './right-nav.element.scss';
import {platformInteractions, updateAppState} from "@web-internal-portal-apps/ipa-core";
import {environment} from "../../environments/environment";

export class RightNavElement extends HTMLElement {
  async connectedCallback() {
    this.innerHTML = `
       <div id="ipaRightNav" class="ipa-right-nav">
          <ipa-icon id="ipaRightNavClose" class="right-nav-close" data-src="${environment.baseUrl}assets/interface-nav-open.svg"></ipa-icon>
          <iframe
            id="iframeRightNav"
            loading="lazy"
            src="about:blank"
            sandbox="allow-downloads allow-forms allow-scripts allow-same-origin allow-popups allow-modals"
            ></iframe>
       </div>
    `;

    const rightNavElem = document.getElementById("ipaRightNav")
    const iframeRightNavElem = document.getElementById("iframeRightNav") as HTMLIFrameElement
    const ipaRightNavClose = document.getElementById("ipaRightNavClose")
    let currentRightNavSrc = undefined;

    rightNavElem.style.display = 'none'
    ipaRightNavClose.addEventListener('click', () => {
      updateAppState.platformInteractions({rightNav:{src: currentRightNavSrc, width: '0', show:false}})
    })

    platformInteractions.subscribe(res => {
      if(res.rightNav){
        if(res.rightNav.show){
          rightNavElem.style.right = '0px'
          rightNavElem.style.width = res.rightNav.width+'px'
          if(currentRightNavSrc !== res.rightNav.src){
            iframeRightNavElem.src = res.rightNav.src
            currentRightNavSrc = res.rightNav.src
          }
          rightNavElem.style.display = 'block'
        } else {
          rightNavElem.style.right = '0'
          rightNavElem.style.width = '0'
          rightNavElem.style.display = 'none'
        }
      }
    })
  }
}

customElements.define('ipa-right-nav', RightNavElement);
