import './left-nav.element.scss';
import {platformInteractions, updateAppState} from "@web-internal-portal-apps/ipa-core";
import {environment} from "../../environments/environment";

export class LeftNavElement extends HTMLElement {
  async connectedCallback() {
    this.innerHTML = `
       <div id="ipaLeftNav" class="ipa-left-nav">
          <ipa-icon id="ipaLeftNavClose" class="left-nav-close" data-src="${environment.baseUrl}assets/interface-nav-close.svg"></ipa-icon>
          <iframe
            id="iframeLeftNav"
            loading="lazy"
            src="about:blank"
            sandbox="allow-downloads allow-forms allow-scripts allow-same-origin allow-popups allow-modals"
            ></iframe>
       </div>
    `;

    const leftNavElem = document.getElementById("ipaLeftNav")
    const ipaLeftNavClose = document.getElementById("ipaLeftNavClose")
    const iframeLeftNavElem = document.getElementById("iframeLeftNav") as HTMLIFrameElement
    let currentLeftNavSrc = undefined;

    ipaLeftNavClose.addEventListener('click', () => {
      updateAppState.platformInteractions({leftNav:{src: currentLeftNavSrc, width: leftNavElem.style.width.replace('px',''), show:false}})
    })

    platformInteractions.subscribe(res => {
      if(res.leftNav){
        if(res.leftNav.show){
          leftNavElem.style.left = '0px'
          leftNavElem.style.width = res.leftNav.width+'px'
          if(currentLeftNavSrc !== res.leftNav.src){
            iframeLeftNavElem.src = res.leftNav.src
            currentLeftNavSrc = res.leftNav.src
          }
        } else {
          leftNavElem.style.left = -res.leftNav.width+'px'
        }
      }
    })
  }
}

customElements.define('ipa-left-nav', LeftNavElement);
