import './header.element.scss';
import {devMode, platformInteractions} from "@web-internal-portal-apps/ipa-core";
import {resizeIFrameToFitContent} from "../side-load/side-load.element";

export class HeaderElement extends HTMLElement {
  async connectedCallback() {
    this.innerHTML = `
       <div id="ipaHeader" class="ipa-header">
         <iframe
          id="iframeHeader"
          loading="lazy"
          src="about:blank"
          sandbox="allow-downloads allow-forms allow-scripts allow-same-origin allow-popups allow-modals"
          ></iframe>
       </div>
    `;

    const ipaHeaderElem = document.getElementById('ipaHeader') as HTMLIFrameElement
    const ipaHeaderIframeElem = document.getElementById('iframeHeader') as HTMLIFrameElement
    let currentHeaderSrc = undefined;
    platformInteractions.subscribe(res => {
      if(res.header.show){
        ipaHeaderIframeElem.style.height = res.header.height + 'px'
        ipaHeaderIframeElem.style.marginTop = (devMode.enabled ? document.getElementById('localUrl').offsetHeight : 0).toString() + 'px'
        if(currentHeaderSrc !== res.header.src){
          ipaHeaderIframeElem.src = res.header.src
          currentHeaderSrc = res.header.src
        }
      } else {
        ipaHeaderIframeElem.style.marginTop = '0'
        ipaHeaderIframeElem.style.height = '0'
      }

      if (res.leftNavMenuItems) {
        if (res.leftNavMenuItems.show) {
          if(res.leftNavMenuItems.isOpen){
            ipaHeaderElem.style.marginLeft = '250px'
          } else {
            ipaHeaderElem.style.marginLeft = '96px'
          }
        } else {
          if (res.leftNavMenuItems.show) {
            ipaHeaderElem.style.marginLeft = '0'
          }
        }
      }
    })


  }
}

customElements.define('ipa-header', HeaderElement);
