import {
  FeatureCardInterface,
  IPlatformInteraction,
  IUserAppStateEntity,
  IUserAppStateEntityClass,
  UpdateAppState,
  IMenuItem
} from "./user-app-state";
import {ReplaySubject} from 'rxjs';
import { takeWhile } from 'rxjs/operators';

export const devMode = {enabled: false}
export const updateAppState = new UpdateAppState;
export const splitScreenMode = {activated:false}
export const listenForFeatureContextChangesSubject = new ReplaySubject<{ keyName:string,payload: any}>(1)
export const featureAppList = new ReplaySubject<FeatureCardInterface[]>(1)
export const platformInteractions = new ReplaySubject<IPlatformInteraction>(1)
export const activeFeature: {primaryFeature: FeatureCardInterface | undefined, secondaryFeature:FeatureCardInterface | undefined} = {primaryFeature: undefined, secondaryFeature:undefined}
export const leftNavMenuItemList = new ReplaySubject<{menuId?:string,menuItemList?:IMenuItem[]}>(1)

// cookie management

export function getCookie(name: string): string {
  const nameLenPlus = name.length + 1;
  return (
    document.cookie
      .split(';')
      .map((c) => c.trim())
      .filter((cookie) => {
        return cookie.substring(0, nameLenPlus) === `${name}=`;
      })
      .map((cookie) => {
        return decodeURIComponent(cookie.substring(nameLenPlus));
      })[0] || (-1).toString()
  );
}

export function setCookie(name: string, value: string, days: number) {
  let expires = '';
  if (days) {
    const date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = '; expires=' + date.toUTCString();
  }
  document.cookie = name + '=' + (value || '') + expires + '; path=/';
}

export function deleteCookie (name: string) {
  document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:01 GMT;';
}

export function deleteAllCookies() {
  const cookies = document.cookie.split('; ');
  for (let c = 0; c < cookies.length; c++) {
    const d = window.location.hostname.split('.');
    while (d.length > 0) {
      const cookieBase =
        encodeURIComponent(cookies[c].split(';')[0].split('=')[0]) +
        '=; expires=Thu, 01-Jan-1970 00:00:01 GMT; domain=' +
        d.join('.') +
        ' ;path=';
      const p = location.pathname.split('/');
      document.cookie = cookieBase + '/';
      while (p.length > 0) {
        document.cookie = cookieBase + p.join('/');
        p.pop();
      }
      d.shift();
    }
  }
}

//get apps list

export function getAppList(){
  return fetch('/features')
    .then((res) => res.json())
    .catch(error => error);
}

//get leftnav menu items list

export function getLeftNavMenuItemList(id:string){

  return fetch(`/dispatch/${id}`)
    .then((res) => res.json())
    .catch(error => error);
}

// get / set app state

export function getAppState() : IUserAppStateEntity {
  const appState = localStorage.getItem('LOCALSTORAGE_APP_STATE_IPA')
  return appState === null ? new IUserAppStateEntityClass().emptyState() : JSON.parse(appState);
}

export function getSpecificAppState(keyName:string) : IUserAppStateEntity {
  const appState = localStorage.getItem('LOCALSTORAGE_APP_STATE_IPA')

  return appState === null ? {} : JSON.parse(appState).featureData[keyName];
}

export function writeToStore(state: any){
  localStorage.setItem('LOCALSTORAGE_APP_STATE_IPA',JSON.stringify(state));
}

// navigate URL
export function navigateUrl(url: string ,  target?:string) {

  function dispatchUrl(stringUrl: string) {
    document.dispatchEvent(
      new CustomEvent('loadAppInIframe', {
        bubbles: true,
        cancelable: false,
        composed: true,
        detail: {
          target: stringUrl,
        },
      })
    );
  }

  if(target !== undefined) {
    window.open(url,target)
  } else{
    dispatchUrl(url)
  }
}

// navigate URL Split screen
export function navigateUrlSplitScreen(url: string) {

  document.dispatchEvent(
    new CustomEvent('loadAppInIframeSplitScreen', {
      bubbles: true,
      cancelable: false,
      composed: true,
      detail: {
        target: url,
      },
    })
  );
}

// navigateAppId
export function navigateAppId(appId: string) {
  const appState = getAppState()

  const appUrl = appState.apps?.find(x => x.id === appId)
  console.log(appUrl)
  if(appUrl !== undefined){
    navigateUrl('https://'+appUrl.url)
  }
}

// get app route from feature list
export function getAppRouteFromFeatureList(pathName:string): Promise<string | undefined>{
  return new Promise((resolve) => {
    let activeSubscription = true;
    featureAppList.pipe(takeWhile(() => activeSubscription)).subscribe(res => {
      const feature = res.find(x => x.meta.urlPathName === pathName)
      activeFeature.primaryFeature = feature;
      activeSubscription = false
      if(feature){
        const route = feature.meta.routes.find(x => x.environment.includes(window.location.hostname) || window.location.hostname === 'ipa.investec.space')
        route ? resolve(route.route):resolve(undefined)
      } else {
        resolve(undefined)
      }
    })
  })
}

// print document
export function printDocument(res: { body: Blob | MediaSource | string; filename: string}) {
  if(typeof res.body === "string"){
    const a = document.createElement('a');
    document.body.appendChild(a);
    a.style.display = 'none';
    a.href = res.body;
    a.download = res.filename;
    a.target = '_blank'
    a.click();
    // window.open(res.body,'_blank');
    a.remove();
  } else {
    window.open(URL.createObjectURL(res.body))
  }

}

// set browser title
export function setTitle(title: string) {
  document.title = title;
}

// reset platform context
export function resetPlatform(){
  localStorage.removeItem('LOCALSTORAGE_APP_STATE_IPA');
}

// Setup default platform
export function setupIpa(app:any){

  getAppList().then(res => {
    updateAppState.apps(res)
    featureAppList.next(res);
    console.log('navigate aApp ID')
    navigateAppId(app)
  })

}
