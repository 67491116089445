export interface PostMessageResponse {
  errors: Array<any>,
  messages: Array<any>,
  result: any,
  success: boolean
}

export function successResponseBuilder(data:any){
  const successRes : PostMessageResponse = {
    errors:[],
    messages:[],
    result: data,
    success:true
  }
  return successRes
}

export function errorResponseBuilder(error:any){
  const successRes : PostMessageResponse = {
    errors:[error.message],
    messages:[],
    result: null,
    success:false
  }
  return successRes
}
export interface HookTarget {
  data: {
    __sdk?: string,
    __permission:string;
    __seq: string;
    __api: string;
    __id: string;
    __msg: any;
    app?: any;
    appId?: string;
  };
  source?: any;
}

export function executeApiEndPoint(endpoint:string, payload?:any){
  if(payload !== undefined){
    return fetch(endpoint,{
      method: 'POST',
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify(payload),
    }).then(res => {
      if(res instanceof Error){
        return Promise.reject('error')
      }
      return res

    }).then(res => res.json()).catch(error => error)
  } else {
    return fetch(endpoint)
      .then((res) => res.json())
      .catch(error => error);
  }
}

export function returnSuccessResponse(target: HookTarget,data:any,timeToExecute?:number){
  target.source.postMessage({
    data: successResponseBuilder(data),
    __seq: target.data.__seq,
    __id: target.data.__id
  }, '*');

  if(timeToExecute){
    // pushMetrics({hookName: target.data.__id,hookApi:target.data.__api,
    //   timeToExecute:(window.performance.now() - timeToExecute),
    //   status:'true',
    // })
  }
}

export function returnErrorResponse(target: HookTarget,error:any,timeToExecute?:number){
  target.source.postMessage({
    data: errorResponseBuilder(error),
    __seq: target.data.__seq,
    __id: target.data.__id
  }, '*');

  if(timeToExecute){
    // pushMetrics({hookName: target.data.__id,hookApi:target.data.__api,
    //   timeToExecute:(window.performance.now() - timeToExecute),
    //   status:'false',
    //   userId: currenUser.zetaId
    // })
  }

}
