import './left-nav-menu-items.element.scss';
import {leftNavMenuItemList, platformInteractions, updateAppState} from "@web-internal-portal-apps/ipa-core";
import {IMenuItem, IPlatformInteraction} from "../../../../../../libs/ipa-core/src/lib/user-app-state";
import {environment} from "../../../environments/environment";

export class LeftNavMenuItemsElement extends HTMLElement {
  connectedCallback() {
    this.innerHTML = `
      <div id="leftNavMenuItemsContainer" class="sidebar">
        <ipa-icon id="lefNavMenuItemsClose" class="left-nav-close" data-src="${environment.baseUrl}assets/interface-nav-close.svg"></ipa-icon>
        <div id="leftNavMenuItems" class="menu-items">
            <!-- menu items will be populated here-->
        </div>

      </div>
      `;

    const containerElem = document.getElementById('ipaLeftNavMenuItemsContainerElement');
    const sideBarElem = document.getElementById('leftNavMenuItemsContainer');
    const leftNavMenuItemsElem =  document.getElementById('leftNavMenuItems');
    const lefNavMenuItemsClose =  document.getElementById('lefNavMenuItemsClose');
    let menuItemList: IMenuItem[] = [];
    let currentInteractionState: IPlatformInteraction

    lefNavMenuItemsClose.addEventListener('click', () => {
      updateAppState.platformInteractions({leftNavMenuItems:{show:currentInteractionState.leftNavMenuItems.show,isOpen:!currentInteractionState.leftNavMenuItems.isOpen}})
    })

    sideBarElem.style.display = 'none'
    platformInteractions.subscribe(res => {
      currentInteractionState = res;
      if(res.leftNavMenuItems.show){
        sideBarElem.style.display = 'flex';
      } else {
        sideBarElem.style.display = 'none'
      }

      if(res.leftNavMenuItems.isOpen){
        containerElem.classList.remove('sidebar-closed');
      } else {
        containerElem.classList.add('sidebar-closed');
      }
    })

    function addSection(
      menuItemsElem:string,
      list: IMenuItem[],
      spacer?: boolean,
      spacerId?: string
    ) {
      if (spacer && spacerId) menuItemsElem += `<hr class='${spacerId}'>`;
      else if (spacer) menuItemsElem += `<hr>`;
      menuItemsElem += '<ul>';
      for (let i = 0; i < list.length; i++) {
        const item = list[i];
        const jsonData = JSON.stringify(item);
        menuItemsElem += `<li class="side-menu-item" id='${item.id}' data='${jsonData}'>
               <ipa-icon data-src="${environment.baseUrl}assets/${item.icon === undefined || item.icon === ''? 'nav-data-exchange.svg':item.icon}"></ipa-icon>
               <span class="menu-item-text ml-2">${item.label}</span>
            </li>`;
      }
      menuItemsElem += '</ul>';

      return menuItemsElem
    }

    function registerLeftNavMenuItems() {
      document.querySelectorAll('li').forEach((item) => {
        item.addEventListener('click', () => {
          const data = JSON.parse(item.getAttribute('data')) as IMenuItem

          document.dispatchEvent(
            new CustomEvent('loadAppInIframe', {
              bubbles: true,
              cancelable: false,
              composed: true,
              detail: {
                target: data.src,
              },
            })
          );
        });
      });
    }

    leftNavMenuItemList.subscribe(res => {
      menuItemList = res.menuItemList;
      leftNavMenuItemsElem.innerHTML = addSection('',menuItemList)
      registerLeftNavMenuItems()
    })
  }
}

customElements.define('ipa-left-nav-menu-items', LeftNavMenuItemsElement);
