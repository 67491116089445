import './error-page.element.scss';

export class ErrorPageElement extends HTMLElement {
  async connectedCallback() {
    this.innerHTML = `
        <div id="errorPage" class="error-page-container">
          <h2>We weren't banking on that</h2>
          <p>We can't seem to find your application in our feature registry</p>
        </div>
    `;

  }
}
customElements.define('ipa-error-page', ErrorPageElement);
