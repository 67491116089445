
import {HookTarget} from "../services/api.service";
import {
  featureAppList, getLeftNavMenuItemList, leftNavMenuItemList,
  navigateAppId,
  navigateUrl,
  printDocument,
  resetPlatform,
  setTitle, updateAppState,
} from "@web-internal-portal-apps/ipa-core";
import {takeWhile} from "rxjs/operators";

export class PlatformHooks {
  hookSwitch(e: HookTarget) {
    switch (e.data.__id) {
      case 'init':
        this.platformInitiate(e);
        break;
      case 'refreshToken':
        this.platformSession(e);
        break;
      case 'interactions.update':
        this.updatePlatformInteraction(e);
        break;
      case 'interactions.getMenuContent':
        this.getMenuContent(e);
        break;
      case 'reset':
        resetPlatform()
        break;
      case 'browserTitle':
        setTitle(e.data.__msg)
        break;
      case 'navigateUrl':
        navigateUrl(e.data.__msg.url,e.data.__msg.target)
        break;
      case 'navigateAppId':
        navigateAppId(e.data.__msg)
        break;
      case 'printDocument':
        printDocument(e.data.__msg)
        break;
      case 'updateUrl':
        window.history.pushState({ html: '', pageTitle: '' }, '', e.data.__msg.url);
        break;
      case 'update':
        updateAppState.all(e.data.__msg)
        break;
      case 'updateSingleFeature':
        updateAppState.singleFeature(e.data.__msg)
        break;
    }
  }

  platformInitiate(target: HookTarget): void {
    if(target.data.__msg.name === 'investec-web-platform'){

      let activeSubscription = true;
      featureAppList.pipe(takeWhile(() => activeSubscription)).subscribe(res => {
        const feature = res.find(x => x.meta.aaid === target.data.__msg.aaid)
        activeSubscription = false

        if(feature){
          this.authApp(feature.meta.aaid,feature.meta.redirectUrl,feature.meta.scopes).then((res:any) => {
            target.source.postMessage(
              {
                data: res,
                __seq: target.data.__seq,
                __id: target.data.__id,
              },
              '*'
            );
          })
        } else {
          target.source.postMessage(
            {
              data: 'forbidden',
              __seq: target.data.__seq,
              __id: target.data.__id,
            },
            '*'
          );
        }
      })
    }
  }

  platformSession(target:HookTarget):void {

    let activeSubscription = true;
    featureAppList.pipe(takeWhile(() => activeSubscription)).subscribe(res => {
      const feature = res.find(x => x.meta.aaid === target.data.__msg.aaid)
      activeSubscription = false

      if (feature) {
        this.refreshToken(feature.meta.aaid,feature.meta.redirectUrl,feature.meta.scopes,target.data.__msg.username).then(res => {
          target.source.postMessage(
            {
              data: res,
              __seq: target.data.__seq,
              __id: target.data.__id,
            },
            '*'
          );
        }).catch(err => {
          target.source.postMessage(
            {
              data: err,
              __seq: target.data.__seq,
              __id: target.data.__id,
            },
            '*'
          );
        })
      }
    })
  }

  authApp(aaid:string,redirectUrl:string, authScopes :string[]){
    return new Promise(resolve => {

      const myMSALObj = this.createMSALObj(aaid,redirectUrl);

      const loginRequest = {
        scopes: authScopes
      };

      myMSALObj.loginPopup(loginRequest).then((res:any) => resolve(res)).catch((error:any) => resolve(error));

    })
  }

  refreshToken(aaid:string,redirectUrl:string, authScopes :string[], username:string){
    return new Promise((resolve,reject) => {

      const myMSALObj = this.createMSALObj(aaid,redirectUrl);

      const loginRequest = {
        scopes: authScopes,
        account: myMSALObj.getAccountByUsername(username)
      };

      myMSALObj.acquireTokenSilent(loginRequest).then((res:any) => {
        resolve(res)
      }).catch((error:any) => {
        console.warn("silent token acquisition fails. acquiring token using popup");
        // @ts-ignore
        if (error instanceof msal.InteractionRequiredAuthError) {
          // fallback to interaction when silent call fails
          myMSALObj.loginPopup(loginRequest).then((res: any) => resolve(res)).catch((error: any) => reject(error));
        }
        else {
          console.warn(error);
        }
      });
    })
  }

  createMSALObj(aaid:string,redirectUrl:string){
    const msalConfig = {
      auth: {
        // 'Application (client) ID' of app registration in Azure portal - this value is a GUID
        clientId: aaid,
        // Full directory URL, in the form of https://login.microsoftonline.com/<tenant-id>
        authority: "https://login.microsoftonline.com/6d6a11bc-469a-48df-a548-d3f353ac1be8",
        // Full redirect URL, in form of http://localhost:3000
        redirectUri: redirectUrl
      },
      cache: { cacheLocation: "sessionStorage",
        // This configures where your cache will be stored
        storeAuthStateInCookie: false,
        // Set this to "true" if you are having issues on IE11 or Edge
      },
      system: {
        loggerOptions: {
          loggerCallback: (level:string, message:string, containsPii:string) => {
            if (containsPii) {
              return;
            }

            switch (level) {
              // @ts-ignore
              case msal.LogLevel.Error:
                console.error(message);
                return;
              // @ts-ignore
              case msal.LogLevel.Info:
                console.info(message);
                return;
              // @ts-ignore
              case msal.LogLevel.Verbose:
                console.debug(message);
                return;
              // @ts-ignore
              case msal.LogLevel.Warning:
                console.warn(message);
                return;
            }
          }
        }
      }
    };

    // @ts-ignore
    return new msal.PublicClientApplication(msalConfig);
  }

  updatePlatformInteraction(target: HookTarget){
    updateAppState.platformInteractions(target.data.__msg)
  }

  getMenuContent(target: HookTarget) {
    getLeftNavMenuItemList(target.data.__msg).then((res) => {
      leftNavMenuItemList.next({menuId:target.data.__msg,menuItemList:res})
      target.source.postMessage(
        {
          data: true,
          __seq: target.data.__seq,
          __id: target.data.__id,
        },
        '*'
      );
    }).catch( () => {
      target.source.postMessage(
        {
          data: false,
          __seq: target.data.__seq,
          __id: target.data.__id,
        },
        '*'
      );
    })
  }
}
